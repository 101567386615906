<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Prescriptions List</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6" v-if="pagination">
          <el-select
            class="select-default"
            v-model="pagination.per_page"
            placeholder="Per page"
            @change="changePerPage()"
          >
            <el-option
              class="select-default"
              v-for="item in perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input
              class="input-sm"
              placeholder="Search"
              v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split"
            ></fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table
            class="table-striped"
            :data="tableData"
            border
            style="width: 100%;"
            height="600"
          >

            <el-table-column type="expand" fixed>
              <template slot-scope="props">
                <!-- <p v-for="(item, index) in props.row.items" :key="index">{{ item }}</p> -->
                <el-table
                  class="table-striped"
                  :data="props.row.items"
                  border
                >
                  <el-table-column type="index"></el-table-column>
                  <el-table-column width="100" label="Prod ID" prop="product.product_id" align="center"></el-table-column>
                  <el-table-column width="250" label="Product Name" prop="product.name"></el-table-column>
                  <el-table-column width="150" label="Frequency" prop="frequency.value" align="center"></el-table-column>
                  <el-table-column width="100" label="Dose 1" prop="dose_1" align="center" 
                  :formatter="(row, column, cellValue, index)=>{ return `${row.dose_1.value} ${row.product.product_type.unit}` }"></el-table-column>
                  <el-table-column width="100" label="Dose 2" prop="dose_2" align="center" 
                  :formatter="(row, column, cellValue, index)=>{ return `${row.dose_2.value} ${row.product.product_type.unit}` }"></el-table-column>
                  <el-table-column width="100" label="Dose 3" prop="dose_3" align="center" 
                  :formatter="(row, column, cellValue, index)=>{ return `${row.dose_3.value} ${row.product.product_type.unit}` }"></el-table-column>
                  <el-table-column width="200" label="Comment" prop="comment.value"></el-table-column>
                  <el-table-column width="200" label="Extra" prop="extra"></el-table-column>
                </el-table>
              </template>
            </el-table-column>

            <el-table-column
              v-for="(column, index) in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :sortable="column.sortable || false"
              :align="column.align"
              :header-align="column.align"
              v-if="!column.hidden"
              :fixed="column.fixed || false"
            ></el-table-column>

            <el-table-column :min-width="200" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <!-- <p-button type="info" size="sm" icon @click="handleLike(props.$index, props.row)">
                  <i class="fa fa-eye"></i>
                </p-button>-->
                <p-button
                  type="success"
                  size="sm"
                  icon
                  @click="handleBill(props.$index, props.row)"
                >
                  <i class="fa fa-credit-card"></i>
                </p-button>
                
                <p-button
                  type="info"
                  size="sm"
                  icon
                  @click="handleEdit(props.$index, props.row)"
                >
                  <i class="fa fa-edit"></i>
                </p-button>

                <p-button
                  type="danger"
                  size="sm"
                  icon
                  @click="handleDelete(props.$index, props.row)"
                >
                  <i class="fa fa-trash"></i>
                </p-button>
                <p-button
                  type="default"
                  size="sm"
                  icon
                  @click="handleView(props.$index, props.row)"
                >
                  <i class="fa fa-file"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info"  v-if="pagination"> 
          <p
            class="category"
          >Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries</p>
        </div>
        <div class="col-sm-6"  v-if="pagination">
          <p-pagination
            class="pull-right"
            v-model="pagination.current_page"
            :per-page="Number.parseInt(pagination.per_page)"
            :total="pagination.total"
            @input="changePage()"
          ></p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Vue from "vue";
import axios from 'axios';
import { Table, TableColumn, Select, Option } from "element-ui";
import PPagination from "src/components/UIComponents/Pagination.vue";
// import users from "../Tables/users.js";
import swal from 'sweetalert2'
import { mapGetters } from "vuex";

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);

export default {
  components: {
    PPagination
  },
  mounted() {
    this.$store.dispatch("fetchPrescriptions", { per_page: 10, page: 1 });
  },
  watch: {
      searchQuery: function (val) {
          this.$store.dispatch("fetchPrescriptions", {
              searchQuery: this.searchQuery,
              per_page: this.pagination.per_page,
              page: this.pagination.current_page
          }); 
      }
  },
  computed: {
    ...mapGetters({
      tableData: "getPrescriptions",
      pagination: "getPagination"
    }),
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      // if (!this.searchQuery) {
      //   if(this.locations)
      //   this.pagination.total = this.locations.length;
      //   else
      //   this.pagination.total = 0
      //   return this.pagedData;
      // }
      // let result = this.locations.filter(row => {
      //   let isIncluded = false;
      //   for (let key of this.propsToSearch) {
      //     let rowValue = row[key].toString();
      //     if (rowValue.includes && rowValue.includes(this.searchQuery)) {
      //       isIncluded = true;
      //     }
      //   }
      //   return isIncluded;
      // });
      // this.pagination.total = result.length;
      // return result.slice(this.from, this.to);
    }
  },
  data() {
    return {
      perPageOptions: ["10", "30", "50", "100", "250", "500"],
      searchQuery: "",
      propsToSearch: [
        "prescription_id",
        "patient_id",
        "patient_name",
        "location_id",
        "location_name",
        "employee_name",
        "diagnosis",
        "note",
        "date"
      ],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 50,
          hidden: true,
          align: "center"
        },
        {
          prop: "prescription_id",
          label: "Presc. ID",
          minWidth: 150,
          fixed: false,
          sortable: true,
          align: "center"
        },
        {
          prop: "patient.patient_id",
          label: "Pat ID",
          minWidth: 150,
          sortable: true,
          align: "left"
        },
        {
          prop: "patient.name",
          label: "Patient Name",
          minWidth: 250,
          align: "left"
        },
        {
          prop: "location.location_id",
          label: "Loc ID",
          minWidth: 150,
          sortable: true,
          align: "left"
        },
        {
          prop: "location.name",
          label: "Location Name",
          minWidth: 250,
          align: "left"
        },
        {
          prop: "diagnosis",
          label: "Diagnosis",
          minWidth: 200,
          sortable: true,
          align: "left"
        },
        {
          prop: "note",
          label: "Note",
          minWidth: 150,
          sortable: true,
          align: "left"
        },
        {
          prop: "employee_id",
          label: "EMP ID By",
          minWidth: 150,
          sortable: true,
          align: "left"
        },
        {
          prop: "employee_name",
          label: "Created By",
          minWidth: 200,
          sortable: true,
          align: "left"
        },
        {
          prop: "date",
          label: "Date",
          minWidth: 150,
          sortable: true,
          align: "left"
        }
      ]
      // tableData: this.locations
    };
  },
  methods: {
    changePerPage() {
      this.$store.dispatch("fetchPrescriptions", {
        per_page: this.pagination.per_page,
        page: 1, searchQuery : this.searchQuery
      });
    },
    changePage() {
      this.$store.dispatch("fetchPrescriptions", {
        per_page: this.pagination.per_page,
        page: this.pagination.current_page, searchQuery : this.searchQuery
      });
    },
    handleLike(index, row) {
      // alert(`Your want to like ${row.name}`);
      // this.$router.push(`/prescriptions/view/${row.id}`)
    },
    handleBill(index, row) {
      this.$router.push(`/billings/create/${row.id}`)
    },
    handleEdit(index, row) {
      this.$router.push(`/prescriptions/edit/${row.id}`)
    },
    handleDelete(index, row) {
      // this.$store.dispatch("deletePrescription", row.id);
      swal({
          title: "Are you sure?",
          text: `You won't be able to revert this!`,
          showCancelButton: true,
          confirmButtonClass: "btn btn-danger btn-fill",
          cancelButtonClass: "btn btn-default btn-fill",
          confirmButtonText: "Delete it!",
          buttonsStyling: false
      })
      .then(result => { this.$store.dispatch("deletePrescription", row.id); })
      .catch(cancel => { console.log(cancel) });
    },
    handleView(index, row) {
      // this.$store.dispatch("deleteBilling", row.id);

      let authToken = JSON.parse(localStorage.getItem('zyro_user_token')).access_token;
      let headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
      };

      axios({
        url: `${process.env.VUE_APP_API_URL}/auth/prescriptionpdf/${row.id}`,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `prescription-${row.prescription_id}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
      // window.open(`${process.env.VUE_APP_API_URL}/prescriptionpdf/${row.id}`, "_blank", );
    }
  }
};
</script>

<style lang="scss">
.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: white !important;
}
</style>
